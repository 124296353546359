.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
  font-family: "Inter";
  font-size: 1rem;
  color: #fff;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 0.375rem;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: zoomIn 0.3s ease-in-out;
  color: #000;
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
  .header {
    font-family: "Inter";
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    .failed {
      filter: brightness(0) saturate(100%) invert(36%) sepia(78%)
        saturate(2880%) hue-rotate(338deg) brightness(80%) contrast(102%);
    }
    img {
      height: 2rem;
      width: 2rem;
    }
  }
  .err-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    .error {
      height: 3rem;
      width: 3rem;
    }
  }

  .content {
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 300;
    color: #666666;
    margin-top: 2rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .content-2 {
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 400;
    color: #666666;
    margin-top: 1.438rem;
    text-align: left;
    margin-left: 3rem;
    margin-bottom: 1.625rem;
    line-height: 1.25rem;
  }
  .input {
    width: 100%;
    border: 1px solid #e8e8e8;
    height: 2.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    margin-top: 1rem;
    input {
      width: 98%;
      height: 2rem;
      border: none;
      outline: none;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #666666;
      padding-left: 1rem;
    }
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    column-gap: 2rem;
    .close {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      color: #1a1a1a;
      cursor: pointer;
    }
    .cancel {
      color: #4d4d4d;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
    }
    .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      background-color: #cc3333;
      height: 2.25rem;
      width: 4.375rem;
      border-radius: 2px;
      .loading-ic {
        border: 0.1rem solid #fff;
        border-top: 0.15rem solid transparent;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .ok {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      background-color: #4db35e;
      height: 2.25rem;
      width: 4.375rem;
      border-radius: 2px;
      .loading-ic {
        border: 0.1rem solid #fff;
        border-top: 0.15rem solid transparent;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .ok-warning {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      background-color: #deaf21;
      height: 2.25rem;
      min-width: 4.375rem;
      width: max-content;
      border-radius: 2px;
      padding: 0px 10px;
      .loading-ic {
        border: 0.1rem solid #fff;
        border-top: 0.15rem solid transparent;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .failed-warning {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      background-color: #cc3333;
      height: 2.25rem;
      min-width: 4.375rem;
      width: max-content;
      border-radius: 2px;
      padding: 0px 10px;
      .loading-ic {
        border: 0.1rem solid #fff;
        border-top: 0.15rem solid transparent;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .rename {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      background-color: #039be5;
      height: 2.25rem;
      width: 4.375rem;
      border-radius: 2px;
      .loading-ic {
        border: 0.1rem solid #fff;
        border-top: 0.15rem solid transparent;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
